@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
	font-family: 'Pretendard';
	font-weight: 45 920;
	font-style: normal;
	font-display: swap;
	src: url('assets/fonts/pretendard/PretendardVariable.woff2') format('woff2-variations');
}

html, body, #root{width:100%; height:100%;}
#root{
	position:relative;
	min-width: 320px;
}
@media screen and (max-width: 500px) {
	:root{
		font-size: 14px;
	}
}

 
@layer base {
	:root {
		--font-sans: Pretendard, "Noto Sans", sans-serif;
		--background: 0 0% 100%;
		--foreground: 222.2 84% 4.9%;

		--card: 0 0% 100%;
		--card-foreground: 222.2 84% 4.9%;
 
		--popover: 0 0% 100%;
		--popover-foreground: 222.2 84% 4.9%;
 
		--primary: 30.6 98.52% 26.47%;
		--primary-foreground: 210 40% 98%;
 
		--secondary: 210 10% 90%;
		--secondary-foreground: 222.2 47.4% 5%;
 
		--muted: 210 0% 70%;
		--muted-foreground: 215.4 10% 46.9%;
 
		--accent: 210 10% 93%;
		--accent-foreground: 222.2 47.4% 5%;
 
		--destructive: 0 84.2% 60.2%;
		--destructive-foreground: 210 40% 98%;

		--border: 214.3 31.8% 91.4%;
		--input: 214.3 31.8% 91.4%;
		--ring: 222.2 84% 4.9%;
 
		--radius: 0.5rem;

		--shadow: rgba(0,0,0,.25);
		--header-width: 500px;
	}
}
input::placeholder{
	color: hsl(var(--muted)) !important;
}

.lucide{
	stroke-linecap: square;
	stroke-linejoin: miter;
	width:1em;
	height:1em;
}

@layer base {
	/* * {
		@apply border-border;
	}
	body {
		@apply bg-background text-foreground;
	} */
}
