#iframeContainer{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

#xrManagerElementContainer {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 100000;
	pointer-events: none;
}

#xrManagerElementContainer>div {
	pointer-events: initial;
	position: fixed;
}



#root{
	position:relative;
	width:100%;
	height:100%;
}

#main {
	position:relative;
	width:100%;
	height:100%;
}
